import React from 'react';
import Layout from '../Layout'

const Platform = () => {
  return (
    <Layout>

    </Layout>
  )
}

export default Platform;