import React from 'react';

export default function BlogPost(){
    return (
        <div>
            <h1>
                Hello Blog
            </h1>
        </div>
    )
}